<template>
	<b-navbar spaced wrapper-class="container">
		<template slot="brand">
			<b-navbar-item :to="{ path: '/dashboard' }" tag="router-link">
				<img alt="moveUP" src="../assets/moveUP-symbol-green.svg" />
			</b-navbar-item>
		</template>
		<template v-if="user && user._id" slot="start">
			<b-navbar-item v-translate :to="{ path: '/dashboard' }" tag="router-link"> Dashboard</b-navbar-item>
		</template>

		<template slot="end">
			<b-navbar-item v-translate href="https://support.orthopedics.moveup.care/" rel="noopener" target="_blank">
				Support
			</b-navbar-item>
			<b-navbar-dropdown v-if="user && user._id" :label="$gettext('Account')">
				<b-navbar-item v-translate :to="{ path: '/settings' }" tag="router-link"> Settings</b-navbar-item>
				<b-navbar-item v-translate :to="{ path: '/logout' }" tag="router-link"> Log out</b-navbar-item>
			</b-navbar-dropdown>
		</template>
	</b-navbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
	computed: {
		...mapState('auth', ['user']),
	},
};
</script>
