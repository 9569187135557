import { checkAuth } from '@moveup/app-core/auth';
import { LoginPage, LogoutPage, NoAccess, SettingsPage, SignUp } from '@moveup/app-core/views';

const TranslationDashboard = () => import('@/views/TranslationDashboard.vue');
const TranslationsEdit = () => import('@/views/TranslationsEdit.vue');

const routes = [
	{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'Login',
		component: LoginPage,
	},
	{
		path: '/logout',
		name: 'Logout',
		component: LogoutPage,
	},
	{
		path: '/no-access',
		name: 'NoAccess',
		component: NoAccess,
		props: { name: 'Translation Admin' },
	},
	{
		path: '/signup',
		name: 'SignUp',
		component: SignUp,
	},
	{
		path: '/dashboard',
		name: 'TranslationDashboard',
		component: TranslationDashboard,
		beforeEnter: checkAuth,
	},
	{
		path: '/dashboard/:source',
		name: 'TranslationDashboard',
		component: TranslationDashboard,
		beforeEnter: checkAuth,
	},
	{
		path: '/settings',
		name: 'Settings',
		component: SettingsPage,
		beforeEnter: checkAuth,
	},
	{
		path: '/translate/:source/:reference/:from/:to',
		name: 'Translate',
		component: TranslationsEdit,
		beforeEnter: checkAuth,
	},
	{
		path: '/translate/:source/:from/:to',
		name: 'Translate',
		component: TranslationsEdit,
		beforeEnter: checkAuth,
	},
];

export default routes;
